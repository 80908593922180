


































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Ref, Vue } from 'vue-property-decorator'
import { CreateCommunityViewModel } from '../../viewmodels/create-community-viewmodel'
import timezones from '@/constants/timezones.json'

@Observer
@Component({
  components: {},
})
export default class BasicInformation extends Vue {
  @Inject({}) vm!: CreateCommunityViewModel
  timezones = timezones
}
